import log from 'loglevel';
import EventSourceWrapper from './eventsourcewrapper';
import EventHandlerStore from './eventhandlerstore';

log.setLevel('warn');

// Object definition
export default class Channel {
  constructor(name, options) {
    if (typeof options !== 'undefined') {
      if ('logLevel' in options) {
        log.setLevel(options.logLevel);
      }
    }
    this.isDebug = (log.getLevel() <= log.levels.DEBUG);
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Channel constructor: channel=' + name);
    }
    // Required fields
    this.name = name;
    this.eventSource = new EventSourceWrapper();
    // In Channel the Event Handler Store is used to keep track of the original
    // handler and it's associated wrapping handler. This is needed for the
    // unbind call.
    this.eventHandlerStore = new EventHandlerStore();
    this.lastEventId = null;

    // Register a handler to keep track of the lastEventId
    const self = this;
    this.eventSource.onany(e => {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Channel: received onany: ' + e.lastEventId);
      }
      self.lastEventId = e.lastEventId;
    });
    // Register so we can capture the lastEventId via onany
    this.eventSource.addEventListener('_PING', e => {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Channel: received _PING: ' + e.lastEventId);
      }
    });
    // Register so we can capture the lastEventId via onany
    this.eventSource.addEventListener('_SEVERED', e => {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Channel: received _SEVERED: ' + e.lastEventId);
      }
    });
  }

  onmessage(handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Channel: setting handler to receive events with no "event" field (onmessage)');
    }
    this.eventSource.onmessage(handler);
  }

  onany(handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Channel: setting handler to receive all messages (onany)');
    }
    this.eventSource.onany(handler);
  }

  // Bind your handler to receive events. The event that comes back will not be
  // the browsers EventMessage object, instead it will be a plain JSON object
  // with the following fields:
  // lastEventId
  // data - converted from a string to JSON using JSON.parse
  // type - the event type
  bind(eventType, handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Channel: Binding to event: ' + eventType);
    }
    const self = this;
    this._addHandler(eventType, handler, e => {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Channel: Received event message : event type=' + eventType + ', event=' + e);
      }
      const newEvent = {};
      if (typeof e.lastEventId !== 'undefined' && e.lastEventId.length > 0) {
        newEvent.lastEventId = e.lastEventId;
      }
      if (typeof e.type !== 'undefined' && e.type.length > 0) {
        newEvent.type = e.type;
      }
      if (typeof e.data !== 'undefined' && e.data.length > 0) {
        try {
          newEvent.data = JSON.parse(e.data);
        } catch (error) {
          newEvent.data = e.data;
        }
      }
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Channel: Passing on recieved event message as : event type=' + eventType + ', new event=' + newEvent);
      }
      handler(newEvent);
    });
  }

  unbind(eventType, handler) {
    // Stored handler could be the original handler or wrapping handler. The
    // EventHandlerStore takes care of that
    const storedHandler = this.eventHandlerStore.remove(eventType, handler);
    this.eventSource.removeEventListener(eventType, storedHandler);
  }

  _addHandler(eventType, handler, wrapperHandler) {
    /* istanbul ignore else */
    if (this.eventHandlerStore.add(eventType, handler, wrapperHandler)) {
      this.eventSource.addEventListener(eventType, wrapperHandler);
      /* istanbul ignore if */
      if (this.isDebug) {
        log.debug('Channel: The handler will been bound to event: ' + eventType);
      }
    } else if (this.isDebug) {
      log.debug('Channel: The handler has already been bound to event: ' + eventType);
    }
  }

  open(handler) {
    const self = this;
    this.eventSource.onopen(e => {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Channel: Connection was opened: channel=' + self.name);
      }
      handler(e);
    });
  }

  error(handler) {
    const self = this;
    this.eventSource.onerror(e => {
      log.warn('Connection error: channel=' + self.name);
      handler(e);
    });
  }

  setEventSource(eventSource) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Channel: Setting new EventSource on Channel');
    }
    this.eventSource.setEventSource(eventSource);
  }

  // Close the channel (underlying EventSource).
  // If keepEventHandlers is true, this allows us to re-register the existing
  // event handlers (handlers passed to addEventListener), which will then get
  // added to the next EventSource we set via Channel.setEventSource. This is
  // useful when we may have a disconnect error and we are reconnecting to Mcsse
  close(keepEventHandlers) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Channel: close(' + keepEventHandlers + ')');
    }
    if (typeof keepEventHandlers === 'undefined' || !keepEventHandlers) {
      /* istanbul ignore if */
      if (this.isDebug) {
        log.debug('Channel: close: clearing eventHandlerStore');
      }
      this.eventHandlerStore.clear();
    }
    if (this.hasEventSource()) {
      /* istanbul ignore if */
      if (this.isDebug) {
        log.debug('Channel: EventSource found, closing EventSource');
      }
      this.eventSource.close(keepEventHandlers);
    }
  }

  hasEventSource() {
    return this.eventSource.hasEventSource();
  }
}
