export default class Utils {
  // eslint-disable-next-line object-shorthand
  static keys(obj) {
    return Object.keys(obj);
  }

  // eslint-disable-next-line object-shorthand
  static isLength(obj, length) {
    return Object.keys(obj).length === length;
  }

  // eslint-disable-next-line object-shorthand
  static isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
}

