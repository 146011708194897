import log from 'loglevel';
import EventHandlerStore from './eventhandlerstore';

const RECEIVE_ALL_MESSAGES = '*';
const MESSAGE = 'message';
const ERROR_EVENT = 'error';
const OPEN_EVENT = 'open';

log.setLevel('warn');

// Object definition
/*
 * Wraps and EventSource and buffers requests to add or remove listeners.
 */
export default class EventSourceWrapper {
  constructor(options) {
    if (typeof options !== 'undefined') {
      if ('logLevel' in options) {
        log.setLevel(options.logLevel);
      }
    }
    this.isDebug = (log.getLevel() <= log.levels.DEBUG);
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper constructor');
    }
    this.eventHandlerStore = new EventHandlerStore();
    this.handlerManager = this._handler();
  }

  onopen(handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: onopen');
    }
    this.eventHandlerStore.add(OPEN_EVENT, handler);
  }

  onmessage(handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: onmessage');
    }
    this.eventHandlerStore.add(MESSAGE, handler);
  }

  onerror(handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: onerror');
    }
    this.eventHandlerStore.add(ERROR_EVENT, handler);
  }

  onany(handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: onany');
    }
    this.eventHandlerStore.add(RECEIVE_ALL_MESSAGES, handler);
  }

  addEventListener(name, handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: addEventListener: ' + name);
    }
    // We store the addEventListeners calls in case the EventSource has not
    // been created yet i.e. the Mcsse.getToken handling has not finished yet.
    // Also we store the handlers in case we close and create a new EventSource
    // under a retry condition e.g a disconnect, or some other error occurs
    const newHandler = this.eventHandlerStore.add(name, handler);
    if (newHandler && typeof this.eventSource !== 'undefined' && typeof this.eventSource.addEventListener === 'function') {
      this.eventSource.addEventListener(name, this.handlerManager);
    }
  }

  removeEventListener(name, handler) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: removeEventListener: ' + name);
    }
    // Remove the handler(s) from the store registered under name
    this.eventHandlerStore.remove(name, handler);
    // Remove a handler from the EventSource if we have an EventSource
    if (typeof this.eventSource !== 'undefined' && typeof this.eventSource.removeEventListener === 'function') {
      this.eventSource.removeEventListener(name, this.handlerManager);
    }
  }

  // Close the EventSource.
  // If keepEventHandlers is true, then do not clear the list of event listeners
  // added to the addEventListeners array. This will allow for the same listeners
  // to get registered again with the next EventSource that gets set via
  // setEventSource. This is useful when we may have a disconnect error and we
  // are reconnecting to Mcsse.
  close(keepEventHandlers) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: close(' + keepEventHandlers + ')');
    }
    if (typeof keepEventHandlers === 'undefined' || !keepEventHandlers) {
      /* istanbul ignore if */
      if (this.isDebug) {
        log.debug('EventSourceWrapper: close: clearing eventHandlerStore');
      }
      this.eventHandlerStore.clear();
    }
    if (typeof this.eventSource !== 'undefined') {
      this.eventSource.close();
      this.eventSource = undefined;
    }
  }

  hasEventSource() {
    return (typeof this.eventSource !== 'undefined');
  }

  isClosed() {
    let isClosed = false;
    if (typeof this.eventSource !== 'undefined' &&
        typeof this.eventSource.readyState !== 'undefined') {
      isClosed = this.eventSource.readyState === 2;
    }
    return isClosed;
  }

  /*
   * Set an EventSource and updated it with any buffered add event listener requests.
   */
  setEventSource(eventSource) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('EventSourceWrapper: setEventSource');
    }
    this.eventSource = eventSource;

    // Set the handler that routes all messages appropriately (checks the event
    // type) to all handlers registered with the Event Handler Store.
    /* eslint-disable unicorn/prefer-add-event-listener */
    this.eventSource.onopen = this.handlerManager;
    this.eventSource.onerror = this.handlerManager;

    // Register the generic 'message' event handlers i.e. when no 'event' field
    // is sent from the publisher of the event.
    this.eventSource.onmessage = this.handlerManager;

    // Register all named event handlers
    const eventTypes = this.eventHandlerStore.eventTypes();
    for (let i = 0; i < eventTypes.length; i++) {
      this.eventSource.addEventListener(eventTypes[i], this.handlerManager);
    }
  }

  // Returns an event handler function that depends on the events name as to
  // which handlers are called. If the event has a name, then handlers for that
  // event and any handlers registered to receive all events are passed the
  // event. If the event does not have a name, then all handlers except the
  // OPEN_EVENT and ERROR_EVENT handlers are called.
  _handler() {
    const self = this;
    return function (ev) {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('EventSourceWrapper: _handler passing on events to handlers. Event type: ' + ev.type);
      }
      const handlers = [];
      if (ev.type !== OPEN_EVENT && ev.type !== ERROR_EVENT) {
        self.eventHandlerStore.eventHandlers(RECEIVE_ALL_MESSAGES, handlers);
      }
      self.eventHandlerStore.eventHandlers(ev.type, handlers);
      self._sendEvent(ev, handlers);
    };
  }

  // Iterates through the list of handlers and send the event.
  _sendEvent(event, handlers) {
    // eslint-disable-next-line guard-for-in, prefer-const
    for (let i = 0; i < handlers.length; i++) {
      const handler = handlers[i];
      /* istanbul ignore if */
      if (this.isDebug) {
        log.debug('EventSourceWrapper: _sendEvent sending event to handler with event type: ' + handler.name);
      }
      handler.func(event);
    }
  }
}
