import log from 'loglevel';
import Retry from 'kinesisjs/src/retry';
import {NativeEventSource, EventSourcePolyfill} from 'event-source-polyfill';
import xhr from 'xhr';
import Channel from './channel';

const EventSource = NativeEventSource || EventSourcePolyfill;

log.setLevel('warn');

const DEFAULT_MAX_RETRY = 9007199254740991; // Maximum number of retries
const DEFAULT_MAX_DELAY = 30 * 1000; // 30 second max delay
const DEFAULT_INITIAL_DELAY = 1000;

/*
 * Library definition
 *
 * @param mcsseUrl - the Mcsse endpoint e.g. https://mcsse.io/sse/
 * @param tokenUrl - the url used to get a subscribe token from
 * @param options - additional options for MCSSE
 * - tokenResponseHandler - (optional) use to parse the response from your
 *   server to return the subscribe token, which is used to connect to MCSSE.
 *   If specified the handler is called when the token request to your server
 *   has returned. A token request can be made at anytime e.g. reconnect after
 *   timeout return a string that is the token. If no handler is specified it
 *   assumed that the returned value from the tokenUrl call is the unchanged
 *   JSON response received from MCSSE auth endpoint.
 * - tokenRetry - number of token request retries. Default: DEFAULT_MAX_RETRY
 * - logLevel - set the log level (trace/debug/info/warn/error)
 * - withCredentials - cross-site Access-Control requests should be made using
 *   credentials such as cookies
 * - maxDelay - the maximum delay in milliseconds before retrying. Default: DEFAULT_MAX_DELAY
 * - initialDelay - the initial delay in milliseconds before retrying. Default: DEFAULT_INITIAL_DELAY
 */
export class Mcsse {
  constructor(mcsseUrl, tokenUrl, options) {
    this.mcsseUrl = mcsseUrl;
    this.tokenRetry = DEFAULT_MAX_RETRY;
    this.maxDelay = DEFAULT_MAX_DELAY;
    this.initialDelay = DEFAULT_INITIAL_DELAY;
    this.withCredentials = false;
    this.eventSourceClass = EventSource;
    this.channelClass = Channel;
    this.retryClass = Retry;
    if (typeof options !== 'undefined') {
      if ('logLevel' in options) {
        log.setLevel(options.logLevel);
      }
      if ('tokenResponseHandler' in options) {
        this.tokenResponseHandler = options.tokenResponseHandler;
      }
      if ('tokenRetry' in options) {
        this.tokenRetry = options.tokenRetry;
      }
      if ('withCredentials' in options) {
        this.withCredentials = options.withCredentials;
      }
      if ('maxDelay' in options) {
        this.maxDelay = options.maxDelay;
      }
      if ('initialDelay' in options) {
        this.initialDelay = options.initialDelay;
      }
      if ('eventSourceClass' in options) {
        this.eventSourceClass = options.eventSourceClass;
      }
      if ('channelClass' in options) {
        this.channelClass = options.channelClass;
      }
      if ('retryClass' in options) {
        this.retryClass = options.retryClass;
      }
    }
    this.isDebug = (log.getLevel() <= log.levels.DEBUG);
    /* eslint-disable new-cap */
    this.retry = new this.retryClass(
      this.tokenRetry,
      this.maxDelay,
      {
        logLevel: log.getLevel(),
        initialDelay: this.initialDelay
      }
    );
    log.info('Mcsse constructor: token url=' + tokenUrl + ', options=' + options);
    // Required field
    this.tokenUrl = tokenUrl;
  }

  /*
   * Listen for events related to successfully getting a subscribe token
   */
  newToken(handler) {
    this.newTokenHandler = handler;
  }

  /*
   * Listen for events related to errors encountered when getting a subscribe
   * token
   */
  error(handler) {
    this.errorHandler = handler;
  }

  /*
   * Get a subscribe token. If successful then create an EventSource using it.
   */
  getToken(channel) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Mcsse: Get a new token');
    }

    const self = this;
    xhr.get(
      this.tokenUrl + '?channel=' + channel.name,
      {
        withCredentials: this.withCredentials
      },
      (err, resp) => {
        // Get the token
        // Check for errors in the response
        if (typeof err !== 'undefined' && err !== null) {
          // Error in the browser that prevents sending the request
          self._sendError({type: 'error', status: 'browser_error', msg: err}, channel);
        } else if (resp.statusCode >= 200 && resp.statusCode < 300) {
          let token;
          if (typeof self.tokenResponseHandler === 'undefined') {
            /* istanbul ignore if */
            if (self.isDebug) {
              log.debug('Mcsse: Get token response: ' + resp.body);
            }
            // Check the this is a string and JSON
            if (typeof resp.body === 'string' && resp.body.trim().indexOf('{') === 0) {
              const body = JSON.parse(resp.body);
              token = body.token;
            } else {
              const msg = (typeof resp.body === 'string' ? 'The token response is not well formed JSON: ' + resp.body.substring(0, 10) : 'The token response is not a string');
              // eslint-disable-next-line object-shorthand
              self._sendError({type: 'error', status: resp.statusCode, msg: msg}, channel);
              return;
            }
          } else {
            token = self.tokenResponseHandler(resp.body);
          }
          if (typeof self.newTokenHandler !== 'undefined') {
            self.newTokenHandler(resp.body); // What should we pass, token?
          }
          try {
            // Construct the EventSource URL. Include the lastEventId if available
            let evUrl = self.mcsseUrl + channel.name + '?a=' + token;
            if (typeof channel.lastEventId !== 'undefined' && channel.lastEventId !== null && channel.lastEventId.length > 0) {
              evUrl += '&lastEventId=' + channel.lastEventId;
            }
            /* eslint-disable new-cap */
            channel.setEventSource(new this.eventSourceClass(evUrl));
          } catch (error) {
            /* istanbul ignore if */
            if (self.isDebug) {
              log.debug('Mcsse: Error setting up the eventsource on the channel: ' + error);
            }
            self.retryGetToken(channel);
          }
        } else {
          // No valid response to get a token, retry later
          self._sendError({type: 'error', status: resp.statusCode, msg: resp.body}, channel);
        }
      }
    );
  }

  _sendError(error, channel) {
    this.retryGetToken(channel);
    if (typeof this.errorHandler !== 'undefined') {
      this.errorHandler(error);
    }
  }

  /*
   * Create a new Channel object.
   *
   * Getting the token and creating an EventSource happen asynchronously.
   * Add your handlers to Mcsse token and error, Channel open and error events to
   * understand the state of the Channel.
   */
  subscribe(channelName) {
    /* istanbul ignore if */
    if (this.isDebug) {
      log.debug('Mcsse: Creating new Channel: ' + channelName);
    }
    /* eslint-disable new-cap */
    const channel = new this.channelClass(channelName, {
      logLevel: log.getLevel()
    });
    // Register the error handler to retry after a disconnect.
    const self = this;
    channel.error(err => {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Mcsse: Error on the channel, retry get token. Channel: ' + channel.name + ', err: ' + JSON.stringify(err));
      }
      self.retryGetToken(channel);
    });
    // Register the open handler to reset the retry object once successfully connected.
    channel.open(() => {
      /* istanbul ignore if */
      if (self.isDebug) {
        log.debug('Mcsse: Open successful on the channel, reset retry to default values. Channel: ' + channel.name);
      }
      self.retry.reset(); // In case it was in use
    });
    this.getToken(channel);
    return channel;
  }

  /*
   * Make a call to Retry's retry function to get the token.
   *
   * Based on the Retry's initialized values this may or may not actually retry the
   * getToken call i.e. if the number of retries have surpassed the max retry value.
   */
  retryGetToken(channel) {
    // Close the old event source if present, keeping the existing registered
    // event handlers so they get re-registered with the new EventSource
    channel.close(true);

    if (this.retry.isWaiting()) {
      /* istanbul ignore if */
      if (this.isDebug) {
        log.debug('Mcsse: retryGetToken called, however we are already waiting to retry');
      }
    } else {
      /* istanbul ignore if */
      if (this.isDebug) {
        log.debug('Mcsse: Retrying the getToken call');
      }
      const self = this;
      this.retry.retry(() => {
        self.getToken(channel);
      }, () => {
        /* istanbul ignore if */
        if (self.isDebug) {
          log.debug('Mcsse: Retry failed callback, calling the error handler if any configured');
        }
        if (typeof self.errorHandler !== 'undefined') {
          self.errorHandler({type: 'error', retriesExhausted: true, msg: 'Retry count exceeded for getToken'});
        }
      });
    }
  }
}
