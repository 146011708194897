import Utils from './utils';

// A event handler store.
// This helps keep track of event handlers passed to your code from client
// code, that you may then add to a resource you do not manage e.g.
// EventSource.
//
// One use case is where you need to wrap the original handler with your own
// handler code. You may do this so you can log out each time the handler is
// called. When removeEventListener or unbind is called the client code may
// pass your the original handler. To remove the actual handler from the
// resource you will have to pass it the wrapping handler. The
// EventHandlerStore helps you keep track of the name, original handler and
// wrapping handler pairings.
//
// For a name you register handlers with, you can't mix original handlers with
// wrapping handlers and original handlers with no wrapping handler
export default class EventHandlerStore {
  constructor() {
    this.originalHandlers = {};
    this.wrappingHandlers = {};
  }

  // Add the originalHandler to the store under name.
  // Optionally you can pass in a wrapping handler. A wrapping handler is one
  // that you may have created to manage calls to the original handler. The
  // original handler is used whenever you want to call remove.
  //
  // name - the name the original handler will be registered with
  // originalHandler - the original handler passed to you by the client code
  // wrappingHandler - the handler created to manage/enhance the calls to the
  // original handler.
  //
  // return true if the handler has not been registered under name before, false
  // otherwise
  add(name, originalHandler, wrappingHandler) {
    const hasWrappingHandler = (typeof wrappingHandler === 'function');
    // Add the original handler
    if (!(name in this.originalHandlers)) {
      this.originalHandlers[name] = [];
    }
    const oh = this.originalHandlers[name];
    if (oh.indexOf(originalHandler) > -1) {
      return false; // Handler is already registered under name
    }
    oh.push(originalHandler);
    // Add the wrapping handler if present
    if (hasWrappingHandler) {
      if (!(name in this.wrappingHandlers)) {
        this.wrappingHandlers[name] = [];
      }
      this.wrappingHandlers[name].push(wrappingHandler);
    }
    return true; // Handler was registered under name
  }

  // Remove the handler(s) registered under name.
  // If you do not pass in an originalHandler, then remove all handlers
  // registered under name. Otherwise just remove the original handler, and
  // associated wrapping handler if present.
  //
  // return the wrapping handler if present, otherwise the original handler
  remove(name, originalHandler) {
    if (name in this.originalHandlers) {
      if (typeof originalHandler === 'function') {
        const oh = this.originalHandlers[name];
        const index = oh.indexOf(originalHandler);
        let handler = oh.splice(index, 1);
        // If there are wrapping handlers, then the index for the matching wrapping
        // handler should be the same as the original handler
        if (!Utils.isEmpty(this.wrappingHandlers) &&
            (typeof this.wrappingHandlers[name] !== 'undefined')) {
          handler = this.wrappingHandlers[name].splice(index, 1);
        }
        // Delete the name key if no handlers remain
        if (oh.length === 0) {
          delete this.originalHandlers[name];
          delete this.wrappingHandlers[name];
        }
        return handler[0];
      }
      // Remove all handlers
      delete this.originalHandlers[name];
      delete this.wrappingHandlers[name];
    }

    return undefined;
  }

  // Put into the handlers array, all handlers associated with eventType
  // eventType - the event type of the handlers to add to handlers array
  // handlers - the array to push the handlers on to
  eventHandlers(eventType, handlers) {
    const hasWrappingHandlers = !Utils.isEmpty(this.wrappingHandlers);
    const ohList = this.originalHandlers[eventType];
    const whList = this.wrappingHandlers[eventType];
    // The ohList should only be undefined for 'message' (onmessage) types if no
    // handler was registered. All other eventType's without an explicit call to
    // addEventListener is filtered by the underlying browser implementation of
    // EventSource
    if (typeof ohList !== 'undefined') {
      for (let i = 0; i < ohList.length; i++) {
        // eslint-disable-next-line object-shorthand
        const handler = {name: eventType, func: ohList[i]};
        if (hasWrappingHandlers) {
          // eslint-disable-next-line object-shorthand
          handler.wrappingFunc = whList[i];
        }
        handlers.push(handler);
      }
    }
  }

  // Put into the handlers array, all handlers excluding those associated with
  // event types in the excludeHandlers array.
  // excludeHandlers - a array with the list of event types to exclude
  // handlers - the array to push the handlers on to
  handlers(excludeHandlers, handlers) {
    // eslint-disable-next-line guard-for-in, prefer-const
    for (let name in this.originalHandlers) {
      if (excludeHandlers.indexOf(name) < 0) {
        this.eventHandlers(name, handlers);
      }
    }
  }

  // Return an array of event types registered with the event handler store.
  // Optionally exclude names from the array returned
  eventTypes(excludeNames) {
    const eventTypes = [];
    // Protect against excludeNames not being passed in
    if (typeof excludeNames === 'undefined') {
      excludeNames = [];
    }
    // eslint-disable-next-line guard-for-in, prefer-const
    for (let name in this.originalHandlers) {
      if (excludeNames.indexOf(name) < 0) {
        eventTypes.push(name);
      }
    }
    return eventTypes;
  }

  clear() {
    this.originalHandlers = {};
    this.wrappingHandlers = {};
  }
}
